<template>
  <div>
    <div id="installContainer" v-if="deferredPrompt != null">
      <button @click="dismiss">dismiss</button>
      <button @click="install">Install</button>
    </div>
      <router-view></router-view>    
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      deferredPrompt: null,
    };
  },
  
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
    
  },

  beforeCreate() {
    this.$store.commit("initializeStore");
    const token = this.$store.state.token;

    this.$axios.defaults.headers.common["Content-type"] = "application/json";

    // const user = this.$store.state.user
    // const profile = this.$store.state.profile

    if (token) {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      this.$axios.defaults.headers.common["Authorization"] = "";
    }
  },

  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    
  },
};
</script>
<style>
.page-loader {
  width: 100%;
  height: 500px;
  margin: 50px 20px;
}
.hidden {
  display: none !important;
}

#installContainer {
  position: absolute;
  top: 1em;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 10;
}

#installContainer button {
  background-color: green;
  border: 1px solid white;
  color: white;
  font-size: 1em;
  padding: 0.75em;
}

#installContainer button:hover {
  background-color: lightGreen;
}
</style>