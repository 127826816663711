import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home";
import AboutView from "@/views/frontend/AboutView";
import ContactView from "@/views/frontend/ContactView";
import FaqView from "@/views/frontend/FaqView";
import TermsView from "@/views/frontend/TermsView";
import PrivacyView from "@/views/frontend/PrivacyView";
import PricingView from "@/views/frontend/PricingView";
import LoginView from "@/views/frontend/LoginView";
import ForgotPasswordView from "@/views/frontend/ForgotPasswordView";
import ResetPasswordView from "@/views/frontend/ResetPasswordView";
import RegisterView from "@/views/frontend/RegisterView";
import Profile from "@/views/backend/Profile";
import BvnVerify from "@/views/backend/BvnVerify";
import DashboardLayout from "@/components/backend/layouts/DashboardLayout";
import Dashboard from "@/views/backend/Dashboard";
import Airtime from "@/views/backend/Airtime";
import BuyData from "@/views/backend/BuyData";
import Cable from "@/views/backend/Cable";
import Electricity from "@/views/backend/Electricity";
import Exam from "@/views/backend/Exam";
import Transactions from "@/views/backend/Transactions";
import Sms from "@/views/backend/Sms";
import AirtimeToCash from "@/views/backend/AirtimeToCash";
import Withdraw from "@/views/backend/Withdraw";
import FundWallet from "@/views/backend/FundWallet";
import TransferBonus from "@/views/backend/TransferBonus";
import Upgrade from "@/views/backend/Upgrade";
import Receipt from "@/views/backend/Receipt";

import FrontendLayout from "@/components/frontend/layouts/FrontendLayout";

import store from "../store";
import auth from "@/api_provider/Auth";

const routes = [
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: "/",
    component: FrontendLayout,
    props: true,
    children: [
      {
        path: "",
        name: "home",
        component: Home,
        meta: {
          title: "Home",
        },
      },
      {
        path: "/about",
        name: "about",
        component: AboutView,
        meta: {
          title: "About Us",
        },
      },
      {
        path: "/contact",
        name: "contact",
        component: ContactView,
        meta: {
          title: "Contact Us",
        },
      },
      {
        path: "/faq",
        name: "faq",
        component: FaqView,
        meta: {
          title: "Frequently Asked Questions",
        },
      },
      {
        path: "/terms-and-conditions",
        name: "terms",
        component: TermsView,
        meta: {
          title: "Terms and Conditions",
        },
      },
      {
        path: "/privacy-policy",
        name: "privacy",
        component: PrivacyView,
        meta: {
          title: "Privacy",
        },
      },
      {
        path: "/pricing",
        name: "pricing",
        component: PricingView,
        meta: {
          title: "Pricing",
        },
      },
      {
        path: "/login",
        name: "login",
        component: LoginView,
        meta: {
          title: "Login",
        },
      },
      {
        path: "/forgot-password",
        name: "forgotPassword",
        component: ForgotPasswordView,
        meta: {
          title: "Forgot Password",
        },
      },
      {
        path: "/password_reset/confirm/",
        name: "passwordReset",
        component: ResetPasswordView,
        meta: {
          title: "Reset Password",
        },
      },
      {
        path: "/register",
        name: "register",
        component: RegisterView,
        meta: {
          title: "Register",
        },
      },
    ],
  },

  {
    path: "/dashboard",
    component: DashboardLayout,
    props: true,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
          requireLogin: true,
          title: "Dashboard",
          home_title: "Dashboard",
        },
        props: true,
      },
      {
        path: "/dashboard/fund-wallet",
        name: "fundWallet",
        component: FundWallet,
        meta: {
          requireLogin: true,
          title: "Fund Wallet",
          home_title: "Home",
        },
        props: true,
      },
      {
        path: "/dashboard/profile",
        name: "profile",
        component: Profile,
        meta: {
          requireLogin: true,
          title: "Profile Information",
          home_title: "Home",
        },
        props: true,
      },
      {
        path: "/dashboard/identity-verification",
        name: "identityVerification",
        component: BvnVerify,
        meta: {
          requireLogin: true,
          title: "Identity Verification",
          home_title: "Home",
        },
        props: true,
      },
      {
        path: "/dashboard/upgrade",
        name: "upgrade",
        component: Upgrade,
        meta: {
          requireLogin: true,
          title: "Upgrade Level",
          home_title: "Home",
        },
      },
      {
        path: "/dashboard/withdraw",
        name: "withdraw",
        component: Withdraw,
        meta: {
          requireLogin: true,
          title: "Withdraw",
          home_title: "Home",
        },
      },
      {
        path: "/dashboard/transfer-bonus",
        name: "transferBonus",
        component: TransferBonus,
        meta: {
          requireLogin: true,
          title: "Transfer Bonus",
          home_title: "Home",
        },
      },
      {
        path: "/dashboard/airtime-to-cash",
        name: "airtime2cash",
        component: AirtimeToCash,
        meta: {
          requireLogin: true,
          title: "Airtime To Cash",
          home_title: "Home",
        },
      },
      {
        path: "/dashboard/purchase-airtime",
        name: "airtime",
        component: Airtime,
        meta: {
          requireLogin: true,
          title: "Airtime Purchase",
          home_title: "Home",
        },
        props: true,
      },
      {
        path: "/dashboard/purchase-data",
        name: "data",
        component: BuyData,
        meta: {
          requireLogin: true,
          title: "Data Purchase",
          home_title: "Home",
        },
        props: true,
      },
      {
        path: "/dashboard/pay-for-tv",
        name: "cable",
        component: Cable,
        meta: {
          requireLogin: true,
          title: "Pay for TV",
          home_title: "Home",
        },
      },
      {
        path: "/dashboard/pay-for-electricity",
        name: "electricity",
        component: Electricity,
        meta: {
          requireLogin: true,
          title: "Pay for Electricity",
          home_title: "Home",
        },
      },
      {
        path: "/dashboard/purchase-exam-pins",
        name: "exam",
        component: Exam,
        meta: {
          requireLogin: true,
          title: "Purchase Exam Pins",
          home_title: "Home",
        },
      },
      {
        path: "/dashboard/send-sms",
        name: "sms",
        component: Sms,
        meta: {
          requireLogin: true,
          title: "Bulk SMS",
          home_title: "Home",
        },
      },
      {
        path: "/dashboard/transactions",
        name: "transaction",
        component: Transactions,
        meta: {
          requireLogin: true,
          title: "Transactions",
          home_title: "Home",
        },
      },
      {
        path: "/dashboard/receipt/:trans_id",
        name: "receipt",
        component: Receipt,
        meta: {
          requiresLogin: true,
          title: "Receipt",
          home_title: "Home",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Check auth
// auth.getProfile();
// // store expiration value
let expired = localStorage.getItem("expiration");
console.log("expiration");
console.log(expired);
if (expired == "true") {
  auth.logout();
}
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requireLogin) &&
    !store.state.isAuthenticated
  ) {
    next("/login");
  } else {
    window.document.title =
      to.meta && to.meta.title
        ? "Topupwave NG - " + to.meta.title
        : "Topupwave NG - Home";
    next();
  }
});

export default router;
