import axios from "axios";
import store from "@/store/index.js";
import { computed } from "vue";

class Auth {
  constructor() {
    this.token = localStorage.getItem("token");
    let userData = localStorage.getItem("user");
    this.user = userData ? JSON.parse(userData) : null;

    if (this.token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
    }
  }

  login(token,refresh) {
    // console.log(refresh);
    store.commit("setToken", token);
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    localStorage.setItem("token", token);
    localStorage.setItem("refresh", refresh);
  }

  setUser(user) {
    store.commit("setUser", user);
    localStorage.setItem("user", JSON.stringify(user));
  }

  logout() {
    store.commit("removeToken");
    store.commit("removeUser");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");
    localStorage.removeItem("user");
    localStorage.removeItem("expiration");
    axios.defaults.headers.common["Authorization"] = "";
  }
}

export default new Auth();
