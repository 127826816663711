<template>
  <LoadingSpinner v-if="pageLoading" />
  <div v-else id="page-top">
    <div id="wrapper">
      <!-- Sidebar -->
      <ul
        class="navbar-nav sidebar sidebar-light accordion"
        id="accordionSidebar"
      >
        <router-link
          to="/dashboard"
          class="sidebar-brand d-flex align-items-center justify-content-center"
        >
          <div class="sidebar-brand-icon">
            <img src="@/assets/backend/img/logo/logo.png" />
          </div>
          <div class="sidebar-brand-text mx-3">{{ site_name}}</div>
        </router-link>
        <hr class="sidebar-divider my-0" />
        <li class="nav-item active">
          <a href="/dashboard" class="nav-link">
            <i class="fas fa-fw fa-th"></i> <span>Dashboard</span>
          </a>
        </li>
        <hr class="sidebar-divider" />
        <div class="sidebar-heading">Funding</div>
        <li class="nav-item">
          <router-link to="/dashboard/fund-wallet" class="nav-link"
            ><i class="fas fa-fw fa-credit-card"></i>
            <span>Fund Wallet</span></router-link
          >
        </li>

        <hr class="sidebar-divider" />
        <div class="sidebar-heading">Airtime &amp; Data</div>

        <li class="nav-item">
          <router-link to="/dashboard/purchase-airtime" class="nav-link"
            ><i class="fas fa-fw fa-mobile"></i>
            <span>Airtime</span></router-link
          >
        </li>

        <li class="nav-item">
          <router-link to="/dashboard/purchase-data" class="nav-link"
            ><i class="fas fa-fw fa-wifi"></i> <span>Data</span></router-link
          >
        </li>

        <hr class="sidebar-divider" />
        <div class="sidebar-heading">UTILITY BILL</div>
        <li class="nav-item">
          <router-link to="/dashboard/pay-for-tv" class="nav-link"
            ><i class="fas fa-fw fa-tv"></i> <span>Cable</span></router-link
          >
        </li>

        <li class="nav-item">
          <router-link to="/dashboard/pay-for-electricity" class="nav-link"
            ><i class="fas fa-fw fa-bolt"></i>
            <span>Electricity</span></router-link
          >
        </li>

        <hr class="sidebar-divider" />
        <div class="sidebar-heading">Exams</div>
        <li class="nav-item">
          <router-link to="/dashboard/purchase-exam-pins" class="nav-link"
            ><i class="fas fa-fw fa-graduation-cap"></i>
            <span>Exam Pins</span></router-link
          >
        </li>

        <hr class="sidebar-divider" />
        <div class="sidebar-heading">OTHERS</div>
        <li class="nav-item">
          <router-link to="/dashboard/airtime-to-cash" class="nav-link"
            ><i class="fas fa-fw fa-handshake"></i>
            <span>Airtime To Cash</span></router-link
          >
        </li>
        <!-- <li class="nav-item">
    <router-link to="/dashboard/withdraw" class="nav-link"
      ><i class="fas fa-fw fa-arrow-down"></i>
      <span>Withdraw</span></router-link
    >
  </li> -->
        <li class="nav-item">
          <router-link to="/dashboard/send-sms" class="nav-link"
            ><i class="fas fa-fw fa fa-envelope"></i>
            <span>SMS</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link to="/dashboard/upgrade" class="nav-link"
            ><i class="fas fa-fw fa-arrow-up"></i>
            <span>Upgrade Account</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link to="/dashboard/transactions" class="nav-link"
            ><i class="fas fa-fw fa-th"></i>
            <span>Transactions</span></router-link
          >
        </li>
        <li class="nav-item">
          <a
            href="https://documenter.getpostman.com/view/16297490/2sA3QwcqQC"
            target="_blank"
            class="nav-link"
          >
            <i class="fas fa-fw fa-edit"></i>

            <span class="menu-title"
              >API Documentation<span class="badge badge-info">New</span></span
            >
          </a>
        </li>
      </ul>
      <!-- Header -->
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <!-- Header -->
          <div>
            <nav
              class="navbar navbar-expand navbar-light bg-navbar topbar mb-4 static-top"
            >
              <button
                id="sidebarToggleTop"
                @click="myFunctionClick()"
                class="btn btn-link rounded-circle mr-3"
              >
                <i class="fa fa-bars"></i>
              </button>
              <a
                class="btn btn-info mr-2"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.topupwave.topupwave"
                ><i class="fa fa-arrow-down"></i> Download Mobile App</a
              >

              <ul class="navbar-nav ml-auto">
                <div class="topbar-divider d-none d-sm-block"></div>
                <li class="nav-item dropdown no-arrow">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="userDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      class="img-profile rounded-circle"
                      src="@/assets/backend/img/man.png"
                      style="max-width: 60px"
                    />
                    <span class="ml-2 d-none d-lg-inline text-white small"
                      >{{ user.first_name }} {{ user.last_name }}</span
                    >
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                    aria-labelledby="userDropdown"
                  >
                    <router-link to="/dashboard/profile" class="dropdown-item">
                      <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                      Profile
                    </router-link>

                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#logoutModal"
                    >
                      <i
                        class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"
                      ></i>
                      Logout
                    </a>

                    <!-- <a class="dropdown-item" @click="logout()" href="#">
          <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Logout
        </a> -->
                  </div>
                </li>
              </ul>
            </nav>

            <div
              class="modal fade"
              id="logoutModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabelLogout"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabelLogout">
                      Hello Boss!
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p>Are you sure you want to logout?</p>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-outline-info"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>

                    <a
                      class="btn btn-primary"
                      href="javascript:void(0)"
                      @click="logout()"
                    >
                      Logout</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <router-view :user="user"></router-view>
        </div>
        <footer class="sticky-footer bg-white">
          <!-- Footer -->
          <div class="container my-auto py-2">
            <div class="copyright text-center my-auto">
              <p class="copyright">
                <a :href="pages.facebook">Facebook</a> |
                <a :href="pages.twitter">Twitter</a> |
                <a :href="pages.instagram">Instagram</a>
              </p>
              <span
                >Copyright &copy; {{ $filters.copyRightDate() }}

                <b
                  ><a href="#"> {{ footer_site_name }} </a></b
                >
              </span>
            </div>

            <a class="scroll-to-top rounded" href="#page-top">
              <i class="fas fa-angle-up"></i>
            </a>
            {{ $filters.whatsapp(pages.whatsapp_phone) }}
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/api_provider/Auth";
import notify from "@/helpers/notify";

export default {
  name: "DashboardLayout",
  data() {
    return {
      user: {},
      pages: {},
      pageLoading: false,
      site_name: process.env.VUE_APP_SITE_NAME,
      footer_site_name: process.env.VUE_APP_FOOTER_SITE_NAME,
    };
  },
  
  created() {
    this.getUser();
  },

  mounted() {
    this.getPages();
  },

  methods: {
    myFunctionClick: function () {
      $("body").toggleClass("sidebar-toggled");
      $(".sidebar").toggleClass("toggled");
      if ($(".sidebar").hasClass("toggled")) {
        $(".sidebar .collapse").collapse("hide");
      }
    },

    async logout() {
      const refresh_token = localStorage.getItem("refresh")
      const formData = {
        refresh_token: refresh_token,
      };
      this.$axios.post("auth/logout/", formData).then((response) => {
        // console.log(response);
        notify.success("Logged out successfully!");
        auth.logout();
        setTimeout(() => (location.href = "/login"), 3500);
      });
    },

    getPages() {
      this.$axios
        .get("get-pages/")
        .then((response) => {
          // console.log(response.data.data);
          let social = response.data.data.social.split("|");

          this.pages = {
            facebook: "https://facebook.com/" + social[0],
            instagram: "https://instagram.com/" + social[1],
            twitter: "https://twitter.com/" + social[2],
            whatsapp_phone: response.data.data.whatsapp_phone,
          };

          // this.manual_details = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        });
    },

    getUser() {
      this.pageLoading = true;
      this.$axios
        .get(`user/`)
        .then((response) => {
          // console.log("from dashboard layout");
          // console.log(response.data.data);
          localStorage.setItem("expiration", false);
          this.user = response.data.data;
          this.pageLoading = false;
          // this.setUser(response.data.data)
        })
        .catch((error) => {
          if (error.response) {
            console.log("checking");
            console.log(JSON.stringify(error.response.data));
            if (error.response.data.errors[0].code == "token_not_valid") {
              localStorage.setItem("expiration", true);
            }
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        });
      this.pageLoading = false;
    },
  },
};
</script>

<style scoped>
.dropdown-item:active {
  background-color: #0a8aaf;
}
.btn-primary {
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}
.btn-info {
  background-color: #1282a3;
  border-color: #fafafa;
}
.badge-info {
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}
.copyright {
  margin-bottom: 20px;
}
.scroll-to-top {
  background-color: darkgray;
  color: darkgray;
}
</style>