<template>
  <!-- Container Fluid-->
  <div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>
    <LoadingSpinner v-if="pageLoading" />
    <div v-else class="row">
      <div class="col-xl-7 col-lg-12 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body">
            <div class="space"></div>
            <p>
              <input
                type="radio"
                name="fund-select"
                id="card"
                value="card"
                v-model="picked"
              />
              Fund with Atm Card
            </p>

            <div
              v-if="picked == 'card'"
              class="card mycard col-xl-12 col-md-12 col-lg-12 mb-2"
            >
              <br />
              <p>You can fund your wallet instantly with your bank atm card</p>
              <div
                class="notification alert alert-success"
                v-if="card_success_flag"
              >
                <span>{{ card_success_flag }}</span>
              </div>
              <div
                class="notification alert alert-danger"
                v-if="card_errors.length"
              >
                <span
                  v-for="card_error in card_errors"
                  v-bind:key="card_error"
                  >{{ card_error }}</span
                >
              </div>

              <form @submit.prevent="submitCard">
                <div class="form-group">
                  <label>Amount</label>
                  <input
                    type="number"
                    name="amount"
                    id="amount"
                    v-model="amount_card"
                    class="form-control"
                  />
                  <p></p>
                  <p v-if="amount_card != 0">
                    You get (Atm Card): {{ amount_card }} - {{ cardCharge }} =
                    {{ totalCharge }}
                  </p>
                </div>
                <div class="form-group">
                  <button type="submit" id="fund" class="btn btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div class="space"></div>

            <p>
              <input
                type="radio"
                name="fund-select"
                id="instant"
                value="instant"
                v-model="picked"
              />
              Fund with Bank Transfer
            </p>
            <div
              v-if="picked == 'instant'"
              class="instant_style col-xl-12 col-md-12 col-lg-12 mb-2"
            >
              <div class="card h-100">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="bank">
                      <h5><b>Rehoboth Bank</b></h5>
                      <div
                        class="col-xl-12 col-lg-12 col-md-12 d-flex justify-content-center"
                      >
                        <img
                          src="@/assets/backend/img/rehoboth_bank.png"
                          class="rehoboth-logo"
                        />
                      </div>
                      <br />
                      <p>
                        <i
                          ><strong>Charges</strong> - Free for less than 5k and
                          30 naira for 5000 and above</i
                        >
                      </p>
                      <div v-if="user.rehoboth_account != null">
                        <p
                          v-for="account in user.rehoboth_account"
                          :key="account.key"
                        >
                          <b>Account Name:</b> {{ account.accountName
                          }}<br /><br />
                          <b>Account Number:</b> {{ account.accountNumber
                          }}<br />
                        </p>
                      </div>
                      <div v-else>
                        <p>
                          <b>Account Name:</b>
                          <br />
                          <b>Account Number:</b>
                        </p>
                        <button
                          v-if="isCreating_rehoboth == false"
                          @click="generateReservedAccount('rehoboth')"
                          class="btn btn-success"
                        >
                          Create Account
                        </button>
                        <p
                          v-else-if="isCreated_rehoboth"
                          class="alert alert-success"
                        >
                          {{ virtual_accounts_rehoboth.message }}
                        </p>
                        <button v-else class="btn btn-info" disabled>
                          Please wait! We are creating your account...
                        </button>
                      </div>
                      <br />
                    </div>

                    <div class="bank">
                      <h5><b>Moniepoint Bank</b></h5>
                      <div
                        class="col-xl-12 col-lg-12 col-md-12 d-flex justify-content-center"
                      >
                        <img
                          src="@/assets/backend/img/moniepoint_bank.png"
                          class="moniepoint-logo"
                        />
                      </div>
                      <p>
                        <i><strong>Charges</strong> - 0.85% per deposit</i>
                      </p>

                      <div v-if="user.moniepoint_account != null">
                        <p
                          v-for="account in user.moniepoint_account"
                          :key="account.key"
                        >
                          <b>Account Name:</b> {{ account.accountName }}<br />
                          <b>Account Number:</b> {{ account.accountNumber
                          }}<br />
                        </p>
                      </div>
                      <div v-else>
                        <!-- <p class="text-danger" style="color: white">
                          You have to update bvn or nin to Create this account.
                          To update
                          <router-link
                            to="/dashboard/identity-verification"
                            class="text-success"
                            >Click Here</router-link
                          >
                        </p> -->
                        <p>
                          <b>Account Name:</b>
                          <br />
                          <b>Account Number:</b>
                        </p>
                        <button
                          v-if="isCreating_moniepoint == false"
                          @click="generateReservedAccount('moniepoint')"
                          class="btn btn-success"
                        >
                          Create Account
                        </button>
                        <p
                          v-else-if="isCreated_moniepoint"
                          class="alert alert-success"
                        >
                          {{ virtual_accounts_moniepoint.message }}
                        </p>
                        <button v-else class="btn btn-info" disabled>
                          Please wait! We are creating your account...
                        </button>
                      </div>
                      <br />
                      <!-- <div
                        class="notification alert alert-warning"
                        v-if="gra_errors_moniepoint"
                      >
                        <span
                          >Please
                          <router-link
                            to="/dashboard/identity-verification"
                            class="text-success"
                            >Click Here</router-link
                          >
                          to validate your bvn according to CBN (Central Bank of
                          Nigeria) directive. After that come back here to
                          generate an account for funding. Thanks</span
                        >
                      </div> -->
                    </div>

                    <div class="bank">
                      <h5><b>Sterling Bank</b></h5>
                      <div
                        class="col-xl-12 col-lg-12 col-md-12 d-flex justify-content-center"
                      >
                        <img
                          src="@/assets/backend/img/sterling_bank.png"
                          class="sterling-logo"
                        />
                      </div>
                      <p>
                        <i><strong>Charges</strong> - 0.85% per deposit</i>
                      </p>

                      <div v-if="user.sterling_account != null">
                        <p
                          v-for="account in user.sterling_account"
                          :key="account.key"
                        >
                          <b>Account Name:</b> {{ account.accountName }}<br />
                          <b>Account Number:</b> {{ account.accountNumber
                          }}<br />
                        </p>
                      </div>
                      <div v-else>
                        <!-- <p class="text-danger" style="color: white">
                          You have to update bvn or nin to Create this account.
                          To update
                          <router-link
                            to="/dashboard/identity-verification"
                            class="text-success"
                            >Click Here</router-link
                          >
                        </p> -->
                        <p>
                          <b>Account Name:</b>
                          <br />
                          <b>Account Number:</b>
                        </p>
                        <button
                          v-if="isCreating_sterling == false"
                          @click="generateReservedAccount('sterling')"
                          class="btn btn-success"
                        >
                          Create Account
                        </button>
                        <p
                          v-else-if="isCreated_sterling"
                          class="alert alert-success"
                        >
                          {{ virtual_accounts_sterling.message }}
                        </p>
                        <button v-else class="btn btn-info" disabled>
                          Please wait! We are creating your account...
                        </button>
                      </div>
                      <br />
                      <!-- <div
                        class="notification alert alert-warning"
                        v-if="gra_errors_moniepoint"
                      >
                        <span
                          >Please
                          <router-link
                            to="/dashboard/identity-verification"
                            class="text-success"
                            >Click Here</router-link
                          >
                          to validate your bvn according to CBN (Central Bank of
                          Nigeria) directive. After that come back here to
                          generate an account for funding. Thanks</span
                        >
                      </div> -->
                    </div>

                    <div class="bank">
                      <h5><b>Wema Bank</b></h5>
                      <div
                        class="col-xl-12 col-lg-12 col-md-12 d-flex justify-content-center"
                      >
                        <img
                          src="@/assets/backend/img/wema_bank.png"
                          class="wema-logo"
                        />
                      </div>
                      <br />
                      <p>
                        <i><strong>Charges</strong> - 0.85% per deposit</i>
                      </p>

                      <div v-if="user.wema_account != null">
                        <p
                          v-for="account in user.wema_account"
                          :key="account.key"
                        >
                          <b>Account Name:</b> {{ account.accountName
                          }}<br /><br />
                          <b>Account Number:</b> {{ account.accountNumber
                          }}<br />
                        </p>
                      </div>
                      <div v-else>
                        <!-- <p class="text-danger" style="color: white">
                          You have to update bvn or nin to Create this account.
                          To update
                          <router-link
                            to="/dashboard/identity-verification"
                            class="text-success"
                            >Click Here</router-link
                          >
                        </p> -->

                        <p>
                          <b>Account Name:</b>
                          <br />
                          <b>Account Number:</b>
                        </p>
                        <button
                          v-if="isCreating_wema == false"
                          @click="generateReservedAccount('wema')"
                          class="btn btn-success"
                        >
                          Create Account
                        </button>
                        <p
                          v-else-if="isCreated_wema"
                          class="alert alert-success"
                        >
                          {{ virtual_accounts_wema.message }}
                        </p>
                        <button v-else class="btn btn-info" disabled>
                          Please wait! We are creating your account...
                        </button>
                      </div>
                      <br />
                      <!-- <div
                        class="notification alert alert-warning"
                        v-if="gra_errors_wema"
                      >
                        <span
                          >Please
                          <router-link
                            to="/dashboard/identity-verification"
                            class="btn-success"
                            >Click Here</router-link
                          >
                          to validate your bvn according to CBN (Central Bank of
                          Nigeria) directive. After that come back here to
                          generate an account for funding. Thanks</span
                        >
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="space"></div>

            <p>
              <input
                type="radio"
                name="fund-select"
                id="manual"
                value="manual"
                v-model="picked"
              />
              Fund with Manual Transfer
            </p>
            <div
              v-if="picked == 'manual'"
              class="card manual_style col-xl-12 col-md-12 col-lg-12 mb-2"
            >
              <div v-html="manual_details.description" class="man_s"></div>

              <p
                v-for="detail in manual_details.products"
                :key="detail.products"
              >
                <b>Acc Name:</b> {{ detail.name }}<br />
                <b>Acc No:</b> {{ detail.account }}<br />
                <b>Bank:</b> {{ detail.bank }}
              </p>
              <hr />
              <div
                class="notification alert alert-success"
                v-if="manual_success_flag"
              >
                <span>{{ manual_success_flag }}</span>
              </div>
              <div
                class="notification alert alert-danger"
                v-if="manual_errors.length"
              >
                <span
                  v-for="manual_error in manual_errors"
                  v-bind:key="manual_error"
                  >{{ manual_error }}</span
                >
              </div>

              <form @submit.prevent="submitManual">
                <div class="form-group">
                  <label>Payment Name</label>
                  <input
                    type="text"
                    name="payment_name"
                    id="payment_name"
                    placeholder="Enter the name on the bank account you transferred from"
                    v-model="payment_name"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Amount</label>
                  <input
                    type="number"
                    name="manual_amount"
                    placeholder="Enter the amount you transferred"
                    id="manual_amount"
                    v-model="manual_amount"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Bank Name</label>
                  <select
                    name="bank_name"
                    id="bank_name"
                    v-model="bank_name"
                    class="form-control"
                    required
                  >
                    <option value="">Select</option>
                    <!-- {% for package in packages %} -->
                    <option
                      v-for="bank in banks"
                      :value="bank.name"
                      :key="bank.key"
                    >
                      {{ bank.name }}
                    </option>
                    <!-- {% endfor %} -->
                  </select>
                </div>
                <div class="form-group">
                  <label>Payment Type</label>
                  <input
                    type="text"
                    name="payment_type"
                    id="payment_type"
                    v-model="payment_type"
                    placeholder="USSD, Mobile Transfer, POS"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <button
                    v-if="isLoading == false"
                    type="submit"
                    class="btn btn-success"
                  >
                    Process
                  </button>
                  <button v-else class="btn btn-success" disabled>
                    Processing...
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---Container Fluid-->
</template>

<script>
export default {
  props: ["user"],
  name: "FundWallet",
  data() {
    return {
      // user: this.auth.user,
      picked: "",
      amount_card: 0,
      payment_name: "",
      manual_amount: "",
      manual_details: {},
      virtual_accounts_rehoboth: {},
      virtual_accounts_vpay: {},
      virtual_accounts_moniepoint: {},
      virtual_accounts_sterling: {},
      virtual_accounts_gtbank: {},
      virtual_accounts_wema: {},
      bank_name: "",
      payment_type: "",
      total: 0,

      isCreating_rehoboth: false,
      isCreating_vpay: false,
      isCreating_moniepoint: false,
      isCreating_sterling: false,
      isCreating_gtbank: false,
      isCreating_wema: false,

      isCreated_rehoboth: false,
      isCreated_vpay: false,
      isCreated_moniepoint: false,
      isCreated_sterling: false,
      isCreated_gtbank: false,
      isCreated_wema: false,

      card_success_flag: "",
      card_errors: [],
      manual_success_flag: "",
      manual_errors: [],
      isLoading: false,
      gra_errors_wema: false,
      gra_errors_moniepoint: false,
      monnify_bank_charge: process.env.VUE_APP_MONNIFY_BANK_CHARGE,
      monnifyAPIKey: process.env.VUE_APP_MONNIFY_API_KEY, // This has both test and product values
      monnifyContractCode: process.env.VUE_APP_MONNIFY_CONTRACT_CODE, // This has both test and product values
      banks: {},
      reserved_account: {},
      pageLoading: false,
    };
  },
  mounted() {
    this.getFundDetails();
    this.getSettings();
    this.getBankList();
    // this.getUser();
  },

  computed: {
    cardCharge() {
      // console.log(Math.floor(this.amount_card * (this.monnify_bank_charge / 100)))
      return Math.floor(this.amount_card * (this.monnify_bank_charge / 100));
    },
    totalCharge() {
      this.total = this.amount_card - this.cardCharge;
      return Math.ceil(this.amount_card - this.cardCharge);
    },
  },

  methods: {
    getBankList() {
      this.$axios
        .get("get-bank-list/")
        .then((response) => {
          // console.log(response);
          this.banks = response.data.data.data;
        })
        .catch((error) => {
          if (error.response) {
            console.log("Check your network connection!");

            // console.log(JSON.stringify(error.response.data.errors[0].detail));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        });
    },

    getFundDetails() {
      this.$axios
        .get("get-manual-funding-details/")
        .then((response) => {
          // console.log(response.data.data);

          this.manual_details = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
            // this.notify.error(error.response.data.message);

            // console.log(JSON.stringify(error.response.data.errors[0].detail));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        });
    },

    getSettings() {
      this.pageLoading = true;
      this.$axios
        .get("get-settings/")
        .then((response) => {
          // console.log(response.data.data.reserved_account_instruction);
          this.reserved_account =
            response.data.data.reserved_account_instruction;
          this.pageLoading = false;

          // this.manual_details = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            this.errors.push(error.response.data.message);

            // console.log(JSON.stringify(error.response.data.errors[0].detail));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }

          this.pageLoading = false;
        });
    },
   
    submitCard(e) {
      if (this.amount_card < 100) {
        this.card_errors.push("Amount can not be less than ₦100");
        this.notify.error("Amount can not be less than ₦100");
      } else {
        window.MonnifySDK.initialize({
          amount: this.amount_card,
          currency: "NGN",
          reference: new String(new Date().getTime()),
          customerFullName: this.user.first_name + " " + this.user.last_name,
          customerEmail: this.user.email,
          apiKey: this.monnifyAPIKey,
          contractCode: this.monnifyContractCode,

          paymentDescription: "Wallet Funding",
          paymentMethods: ["CARD"],

          onLoadStart: () => {
            console.log("loading has started");
          },
          onLoadComplete: () => {
            console.log("SDK is UP");
          },

          onComplete: (response) => {
            //Implement what happens when the transaction is completed.
            // console.log(response);

            const status = response["status"];
            const transaction_reference = response["transactionReference"];

            if (status == "SUCCESS") {
              const formData = {
                transaction_reference: transaction_reference,
                monnify_bank_charge: this.monnify_bank_charge,
              };
              this.$axios
                .post("user/", formData)
                .then((response) => {
                  // console.log(response.data.data);

                  this.card_success_flag = response.data.message;
                  this.notify.success(response.data.message);
                  setTimeout(
                    () => location.href='/dashboard',
                    3500
                  );
                  // this.manual_details = response.data.data;
                })
                .catch((error) => {
                  if (error.response) {
                    this.errors.push(error.response.data.message);
                    this.notify.error(error.response.data.message);
                    // console.log(JSON.stringify(error.response.data.errors[0].detail));
                  } else if (error.message) {
                    console.log(JSON.stringify(error.message));
                  } else {
                    console.log(JSON.stringify(error));
                  }
                });
            }
          },
          onClose: (data) => {
            //Implement what should happen when the modal is closed here
            // console.log(data);
            console.log("closed");
          },
        });
      }
    },

    submitManual(e) {
      const formData = {
        payment_name: this.payment_name,
        manual_amount: this.manual_amount,
        bank_name: this.bank_name,
        payment_type: this.payment_type,
      };
      if (this.payment_name == "") {
        this.manual_errors.push("Payment Name is required!");
        this.notify.error("Payment Name is required");
      } else if (this.manual_amount == "") {
        this.manual_errors.push("Amount is required!");
        this.notify.error("Amount is required");
      } else if (this.bank_name == "") {
        this.manual_errors.push("Bank Name is required!");
        this.notify.error("Bank Name is required");
      } else if (this.payment_type == "") {
        this.manual_errors.push("Payment Type is required!");
        this.notify.error("Payment Type is required");
      } else {
        // console.log(formData);
        this.isLoading = true;
        this.$axios
          .post("manual-payment/", formData)
          .then((response) => {
            // console.log(response.data.data);
            this.isLoading = false;
            this.manual_success_flag = response.data.message;
            this.notify.error(response.data.message);
            // this.$router.push("/dashboard");
            // this.manual_details = response.data.data;
          })
          .catch((error) => {
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify.error(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },

    generateReservedAccount(bank) {
      if (bank == "rehoboth") {
        this.isCreating_rehoboth = true;
      } else if (bank == "vpay") {
        this.isCreating_vpay = true;
      } else if (bank == "moniepoint") {
        this.isCreating_moniepoint = true;
      } else if (bank == "sterling") {
        this.isCreating_sterling = true;
      } else if (bank == "gtbank") {
        this.isCreating_gtbank = true;
      } else if (bank == "wema") {
        this.isCreating_wema = true;
      }

      if (bank == "rehoboth" || bank == "vpay") {
        // if (bank == "rehoboth") {
        //   this.isCreating_rehoboth = false;
        // }

        this.$axios
          .get(`user/generate-reserved-account/${bank}`)
          .then((response) => {
            // console.log(response.data.data);

            if (bank == "rehoboth") {
              this.virtual_accounts_rehoboth = response.data.data;
              this.isCreating_rehoboth = false;
            }

            if (bank == "vpay") {
              this.virtual_accounts_vpay = response.data.data;
              this.isCreating_vpay = false;
            }

            this.notify.success(
              "Account created successfully, kindly click on fund wallet, choose bank transfer to see your accounts."
            );
            // location.href = "/dashboard";

            setTimeout(() => this.$router.go(this.$currentRoute), 3500);
            // this.isCreating = false;
          })
          .catch((error) => {
            if (error.response) {
              // this.errors.push(error.response.data.message);
              this.notify.error(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            if (bank == "rehoboth") {
              this.isCreating_rehoboth = false;
            }
            if (bank == "vpay") {
              this.isCreating_vpay = false;
            }
          });
      }
      // else if (this.user.bvn == null && this.user.nin == null) {
      //   if (bank == "moniepoint") {
      //     this.isCreating_moniepoint = false;
      //     this.gra_errors_moniepoint = true;
      //   } else if (bank == "wema") {
      //     this.isCreating_wema = false;
      //     this.gra_errors_wema = true;
      //   }
      // }
      else {
        this.$axios
          .get(`user/generate-reserved-account/${bank}`)
          .then((response) => {
            if (bank == "moniepoint") {
              this.virtual_accounts_moniepoint = response.data.data;
              this.isCreating_moniepoint = false;
            } else if (bank == "sterling") {
              this.virtual_accounts_sterling = response.data.data;
              this.isCreating_sterling = false;
            } else if (bank == "gtbank") {
              this.virtual_accounts_gtbank = response.data.data;
              this.isCreating_gtbank = false;
            } else if (bank == "wema") {
              this.virtual_accounts_wema = response.data.data;
              this.isCreating_wema = false;
            }

            this.notify.success(
              "Account created successfully, kindly click on fund wallet, choose bank transfer to see your accounts."
            );
            setTimeout(() => this.$router.go(this.$currentRoute), 3500);
            // this.isCreating = false;
          })
          .catch((error) => {
            // console.log(error.response.data.message)
            if (error.response) {
              console.log(error.response.data.message);
              // this.errors.push(error.response.data.message);
              this.notify.error(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            if (bank == "moniepoint") {
              this.isCreating_moniepoint = false;
            } else if (bank == "sterling") {
              this.isCreating_sterling = false;
            } else if (bank == "gtbank") {
              this.isCreating_gtbank = false;
            } else if (bank == "wema") {
              this.isCreating_wema = false;
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.space {
  margin-bottom: 20px;
}
.manual_style {
  padding: 30px;
}
.man_s {
  margin-bottom: 1rem;
}
label {
  font-weight: bold;
}
a:link {
  text-decoration: none;
}
.btn-primary,
.btn-success {
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}
.mycard {
  padding: 0 20px 0 20px;
}
.wema-logo {
  width: 150px;
  height: 80px;
  margin: 20px 0 10px;
}
.moniepoint-logo {
  width: 180px;
  height: 100px;
  margin: 20px 0 10px;
}
.rehoboth-logo {
  width: 200px;
  height: 60px;
  margin: 20px 0 10px;
}
.vpay-logo {
  width: 100px;
  height: 100px;
}
.sterling-logo {
  width: 120px;
  height: 30px;
  margin: 20px 0 20px;
}
.gtbank-logo {
  width: 200px;
  height: 100px;
}

.wema_account {
  margin: 20px 0 20px 10px;
}

.bank {
  width: 100%;
  margin: 10px 10px 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #0a8aaf;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.text-success {
  color: #0a8aaf !important;
}
</style>