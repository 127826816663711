import { createStore } from 'vuex'

export default createStore({
  state:  {
      isAuthenticated: false,
      token: '',
      user: {},
  },
  getters: {
    token(state) {
      return state.token;
    },
    user (state){
      return state.user;
    },
  },
  mutations: {
    initializeStore(state){
      if(localStorage.getItem('token') && localStorage.getItem('expiration') == "false"){
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      }else{
        state.token = ''
        state.isAuthenticated = false
      }
    },
    setToken(state,token){
      // console.log(data)
      state.token = token
      state.isAuthenticated = true
    },

    setUser(state,user){
      state.user = user
    },

    removeUser(state) {
      state.user = null
    },
    
    removeToken(state){
      state.token =''
      state.isAuthenticated = false
    },


  },
  actions: {
  },
  modules: {
  }
})
