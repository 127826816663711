import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import Auth from "./api_provider/Auth";
import moment from "moment";
import numeral from "numeral";
import VueHtmlToPaper from "./plugins/VueHtmlToPaper";

import "./registerServiceWorker";
import notify from "./helpers/notify";
import LoadingSpinner from "./components/LoadingSpinner.vue";
import LoadingPlan from "./components/LoadingPlan.vue";

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
};
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

const app = createApp(App);
app.use(store);
app.use(router);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.auth = Auth;
app.config.globalProperties.notify = notify;
app.use(VueHtmlToPaper, options);
app.component("LoadingSpinner", LoadingSpinner);
app.component("LoadingPlan", LoadingPlan);

app.config.globalProperties.$filters = {
  formatNumber(numberString) {
    return numeral(numberString).format("0,0.00");
  },

  translateLevel(levelString) {
    // console.log(this.user_level)
    switch (levelString) {
      case 0:
        return "Normal";
        break;
      case 1:
        return "Agent";
        break;
      case 2:
        return "Reseller";
        break;
      case 3:
        return "API";
        break;

      default:
        return "Normal";
        break;
    }
  },
  formatDate(dateString) {
    const date = new Date(dateString);
    return moment(date).format("Do MMM, YYYY");
  },
  formatDateTime(dateString) {
    const date = new Date(dateString);
    return moment(date).format("Do MMM, YYYY,  h:mm:ss a");
    // Then specify how you want your dates to be formatted
    // return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
  },
  copyRightDate() {
    return new Date().getFullYear();
  },
  websiteName() {
    return process.env.VUE_APP_BASE_URL;
  },
  whatsapp(phoneString) {
    var options = {
      whatsapp: phoneString, // WhatsApp number
      call_to_action: "Message us", // Call to action
      button_color: "#FF6550", // Color of button
      position: "left", // Position may be 'right' or 'left'
    };
    var proto = document.location.protocol,
      host = "getbutton.io",
      url = proto + "//static." + host;
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = url + "/widget-send-button/js/init.js";
    s.onload = function () {
      WhWidgetSendButton.init(host, proto, options);
    };
    var x = document.getElementsByTagName("script")[0];
    x.parentNode.insertBefore(s, x);
  },
  makeSlider(imageString) {
    return axios.defaults.baseURL + imageString;
  },
};
app.mount("#app");
